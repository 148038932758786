/* Need put theme of MUIU, but makeStyle is Legacy in @mui/material version 5 */
/* If we are going to change the style, we need to think of something better to style this component */

:root {
  --primary: rgba(171, 3, 0, 0.291);
  --primaryLight: rgb(171, 3, 0);
}

.rs-picker-daterange-header {
  font-size: 14px;
}

.rs-picker-toggle-placeholder {
  font-size: 16px;
}

.rs-picker-toggle-textbox {
  background-color: #f6f6f6;
  font-size: 16px;
}

.rs-calendar-table-cell-in-range::before {
  background-color: var(--primary);
}

.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  background-color: var(--primary);
  color: white;
}

/* .rs-picker-daterange:hover {
  outline: 1px solid black;
  border-radius: 4px;
} */

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  -webkit-box-shadow: inset 0 0 0 1px var(--primary);
  -webkit-box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
  box-shadow: inset 0 0 0 1px var(--primary);
  box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
}

.rs-btn-link:hover,
.rs-btn-link:focus {
  color: var(--primary);
}

.rs-picker-daterange {
  outline: 1px solid #c4c4c4;
  border-radius: 4px;
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 2px solid var(--primaryLight);
  border-radius: 4px;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  border-color: var(--primary);
}

.rs-btn-primary {
  background-color: var(--primaryLight);
}

.rs-btn-primary.rs-btn-disabled,
.rs-btn-primary:disabled {
  background-color: var(--primary);
}

.rs-btn-primary:hover {
  background-color: var(--primaryLight);
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: var(--primaryLight);
}

.rs-btn-link {
  color: var(--primaryLight);
}

.rs-btn .rs-btn-link .rs-btn-sm :hover {
  color: var(--primaryLight);
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #8f8f8f;
  font-size: 16px;
}
